@import './vars.scss';
@import './fonts.scss';
@import './reset.scss';
@import './browser-elements/index.scss';
@import './animations.scss';
* {
  box-sizing: border-box;
}

:root {
  font-size: 16px;
}

body {
  font-family: $font-3;
}

strong {
  font-family: $font-2;
}
