@font-face {
  font-family: "AvenirBlack";
  src: url("fonts/AvenirLTStd-Black.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}
@font-face {
  font-family: "AvenirLight";
  src: url("fonts/AvenirLTStd-Light.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}
@font-face {
  font-family: "Avenir";
  src: url("fonts/AvenirLTStd-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}
@font-face {
  font-family: "AvenirHeavy";
  src: url("fonts/AvenirLTStd-Heavy.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}
