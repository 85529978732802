h1 {
    font-size: 2rem;
}
h2 {
    font-size: 1.6rem;
}
h3 {
    font-size: 1.4rem;
}
h4 {
    font-size: 1.2rem;
}
p {
    font-size: 1rem;
}