$font-1: 'AvenirBlack';
$font-2: 'AvenirHeavy';
$font-3: 'AvenirLight';
$font-4: 'AvenirLight';

$color-main: #f3f3f3;
$color-main-light: #f3f3f3;
$color-main-dark: #24252d;
$color-highlight: #f83563;
$color-highlight-dark: darken($color-highlight, 10%);
$color-highlight-darker: darken($color-highlight, 15%);
$color-1: #fff;
$color-2: $color-main;
$color-3: darken($color-main, 5%);
$color-4: darken($color-main, 10%);
$color-5: darken($color-main, 15%);
$color-6: darken($color-main, 20%);
$color-7: darken($color-main, 25%);
$color-8: darken($color-main, 30%);
$color-9: darken($color-main, 35%);
$color-10: #000;
$color-success: green;
$color-warning: orange;
$color-error: red;
$color-white: #fff;
$color-black: #000;

$border-radius: 5px;
$border-radius-rounded: em(100);
$border-radius-circle: 50%;
$transition-in: all 0.3s ease;
$transition-out: all 0.1s ease;
$transiton-slower-in: all 0.5s ease;
$box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
$inset-box-shadow: inset 0 -35px 55px 0 rgba(0, 0, 0, 0.75);

$std-block-padding: 1rem;
$std-element-padding: 1rem;

$slide-from-left: 0.3s ease 0s 1 slideInFromLeft;

$sidebar-btn-top: 100; // used to set base height of first sidebar button
$sidebar-width: 300px;
